import { NgModule } from '@angular/core';
import { AuthState } from '@app/features/auth/store/auth.state';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

// modules
const modules: any[] = [AuthRoutingModule, SharedModule, NgxsModule.forFeature([AuthState])];

@NgModule({
  imports: modules,
  exports: modules,
})
export class AuthModule {}
