<div class="background">
  <div class="login p-5">
    <div [inlineSVG]="environment.assetsUrl + 'logo-black.svg'" class="pb-5" alt="Adtrac"></div>
    <h3 class="mat-h3 pb-2">{{ 'changePassword' | translate }}</h3>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-form-field>
        <mat-label>{{ 'user.oldPassword' | translate }}</mat-label>
        <input
          matInput
          clearInput
          autocomplete="current-password"
          type="password"
          formControlName="oldPassword"
          id="change_password_txt_old"
          [showMatError]="oldPwdMatError"
        />
        <mat-error #oldPwdMatError/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'user.newPassword' | translate }}</mat-label>
        <input
          autocomplete="new-password"
          matInput
          clearInput
          type="password"
          formControlName="password"
          id="change_password_txt_new"
          [showMatError]="newPwdMatError"
        />
        <mat-error #newPwdMatError/>
      </mat-form-field>
      <mat-form-field class="pb-4">
        <mat-label>{{ 'user.repeatNewPassword' | translate }}</mat-label>
        <input
          autocomplete="new-password"
          matInput
          clearInput
          type="password"
          formControlName="confirmPassword"
          id="change_password_txt_confirm"
          [showMatError]="confirmPwdMatError"
        />
        <mat-error #confirmPwdMatError/>
      </mat-form-field>

      <button
        [buttonLoader]="loading"
        type="submit"
        mat-flat-button
        color="primary"
        appInvalidControlScroll
        id="change_password_btn_submit"
      >
        {{ 'button.submit' | translate }}
      </button>
    </form>
    <a class="navigate-back mt-3" [routerLink]="['/']">
      <mat-icon>chevron_left</mat-icon> {{ 'button.back' | translate }}
    </a>
  </div>
</div>
