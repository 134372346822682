import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { AuthState } from '../store/auth.state';

export const twoFaSetupResolver: ResolveFn<{ qrCode: string; secret: any }> = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const authService = inject(AuthenticationService);

  return store.select(AuthState.twoFaToken).pipe(
    tap((_) => {
      !_ && router.navigate(['login']);
    }),
    switchMap((token) => authService.setup2FA(token)),
  );
};
