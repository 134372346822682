import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from '@auth/components/change-password/change-password.component';
import { LoginComponent } from '@auth/components/login/login.component';
import { SetPasswordComponent } from '@auth/components/set-password/set-password.component';
import { twoFaSetupResolver } from './resolvers/two-fa-setup.resolver';
import { TwoFASetupComponent } from './components/2fa-setup/2fa-setup.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: '2fa-setup',
    component: TwoFASetupComponent,
    resolve: { data: twoFaSetupResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
