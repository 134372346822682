<div class="background">
  <div class="login p-5">
    <div [inlineSVG]="environment.assetsUrl + 'logo-black.svg'" class="pb-5" alt="Adtrac"></div>
    @if(!!legalEntityName){
    <h2 class="mat-h4 pb-2">{{ 'site.legalEntity' | translate }}: {{ legalEntityName }}</h2>
    }
    <h3 class="mat-h3 pb-2">{{ 'setPassword' | translate }}</h3>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-form-field>
        <mat-label>{{ 'user.password' | translate }}</mat-label>
        <input
          autocomplete="new-password"
          matInput
          type="password"
          formControlName="password"
          clearInput
          [showMatError]="newPwdMatError"
        />
        <mat-error #newPwdMatError/>
      </mat-form-field>
      <mat-form-field class="pb-4">
        <mat-label>{{ 'user.passwordRepeat' | translate }}</mat-label>
        <input
          autocomplete="new-password"
          matInput
          type="password"
          formControlName="confirmPassword"
          clearInput
          [showMatError]="confirmMatError"
        />
        <mat-error #confirmMatError/>
      </mat-form-field>
      <button
        [buttonLoader]="loading"
        type="submit"
        mat-flat-button
        color="primary"
        id="set_password_submit_btn"
        appInvalidControlScroll
      >
        {{ 'button.submit' | translate }}
      </button>
    </form>
  </div>
</div>
